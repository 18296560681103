import * as S from './CookiesAcceptTiger.styles';

export function CookiesAcceptTiger({ onClose }: { onClose: () => void }) {
  return (
    <S.Wrapper>
      <S.Modal>
        <S.Title>Cookies</S.Title>
        <S.SubTitle>
          To provide you with an enhanced and personalized experience, we use profiling cookies for content and
          advertising tailored specifically to you. By selecting "Accept all Cookies," you contribute to a seamless and
          customized browsing journey. Your privacy and preferences are our priority.
        </S.SubTitle>
        <S.AcceptButton onClick={onClose}>
          <S.AcceptButtonInner>
            <S.ButtonText>Accept all</S.ButtonText>
          </S.AcceptButtonInner>
        </S.AcceptButton>
      </S.Modal>
    </S.Wrapper>
  );
}
