import styled from 'styled-components';
import ScoreBgWebp from '../../../../assets/buttons/score.webp';
import TigerScoreBgWebp from '../../../../assets/buttons/tiger_score.webp';


export const Score = styled.div<{ $isWebView: boolean }>`
  cursor: default;
  position: fixed;
  top: 60px;
  right: 60px;
  height: 54px;
  width: 214px;
  background-image: url(${ScoreBgWebp});
  background-size: cover;
  z-index: 10;

  @media (max-width: 800px) {
    top: 25px;
    height: 36px;
    width: 148px;
    right: calc(50% - 74px);
    ${props => props.$isWebView && `
      top: 60px;
    `}
  }
`;


export const ScoreText = styled.div`
  background-image: linear-gradient(180deg, #fde99d 0%, #c69d4b 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: 'Inknut Antiqua', serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 28px;
  margin-left: 65px;
  margin-top: 12px;
  user-select: none;
  -webkit-user-select: none;
  @media (max-width: 800px) {
    font-size: 16px;
    line-height: 19px;
    margin-left: 45px;
    margin-top: 8px;
  }
`;


export const TigerScore = styled.div<{ $isWebView: boolean }>`
  cursor: default;
  position: fixed;
  top: 50px;
  right: calc(50% + 80px);
  height: 40px;
  width: 150px;
  background-image: url(${TigerScoreBgWebp});
  background-size: cover;
  z-index: 10;

  @media (max-width: 800px) or (max-height: 1100px) {
    top: 20px;
    right: calc(50% + 10px);
  }
`;

export const TigerScoreText = styled.div`
  color: #FFF;
  font-family: "Noticia Text";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-left: 45px;
  margin-top: 10px;
`;
