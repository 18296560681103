import { useEffect, useRef, useState } from 'react';
import { useGameState } from '../../providers/GameProvider.hooks';
import GameMachine from './components';
import Modal from './components/Modal';
import Landing from './components/Landing';

import * as S from './Game.styles';
import Leaderboard from './components/Leaderboard';
import { numberWithSpaces } from '../../utils/number';
import ModalBackWebp from '../../assets/modal/background.webp';
import ButtonWebp from '../../assets/modal/button.webp';
import LeaderboardBackWebp from '../../assets/leaderboard/background.webp';
import Leader1Webp from '../../assets/leaderboard/leader_1.webp';
import Leader2Webp from '../../assets/leaderboard/leader_2.webp';
import Leader3Webp from '../../assets/leaderboard/leader_3.webp';
import HowToPlay from './components/HowToPlay';
import { isShowTermsAndPrivacy } from '../../utils/routes';
import { CookiesAccept, CookiesAcceptTiger } from './components/CookiesAccept';
import { AppModes } from '../../providers/GameProvider.types';
import HelpButton from './components/HelpButton';
import LeaderboardButton from './components/LeaderboardButton';
import Score from './components/Score';

function App() {
  const { totalWinAmount, isWinningRound, roundWinAmount, config, isShowLanding, appMode } = useGameState();
  const [isShowLeaderboard, setShowLeaderboard] = useState(false);
  const [isShowHowToPlay, setShowHowToPlay] = useState(false);
  const [isCookiesAccepted, setCookiesAccepted] = useState(window.localStorage.getItem('isCookiesAccepted') === 'true');
  const [showVideo, setShowVideo] = useState(false);
  const [showWinAnimation, setShowWinAnimation] = useState(false);
  const videoRef = useRef<null | HTMLVideoElement>(null);
  const params = new URLSearchParams(window.location.search);
  const isWebView = params.get('webView') === 'true';

  useEffect(() => {
    if (isWinningRound) {
      setShowVideo(true);
      setShowWinAnimation(true);
      if (videoRef.current) {
        videoRef.current.currentTime = 0;
        videoRef.current.play();
      }
      setTimeout(() => {
        setShowVideo(false);
      }, 2800);
      setTimeout(() => {
        setShowWinAnimation(false);
      }, 2500);
    }
  }, [isWinningRound]);
  useEffect(() => {
    const IMAGES_TO_PRELOAD = [ModalBackWebp, ButtonWebp, LeaderboardBackWebp, Leader1Webp, Leader2Webp, Leader3Webp];

    Promise.all([
      ...IMAGES_TO_PRELOAD.map((imageSrc) => {
        return new Promise((resolve, reject) => {
          const loadImg = new Image();
          loadImg.src = imageSrc;
          loadImg.onload = () => resolve(imageSrc);
          loadImg.onerror = (err) => reject(err);
        });
      }),
    ]).then((allPromises: any) => {
      // console.log('imgs loaded');
    });
  }, []);

  return (
    <S.AppWrapper $isTigerUi={appMode === AppModes.TIGER}>
      <HelpButton isWebView={isWebView} setShowHowToPlay={setShowHowToPlay}></HelpButton>
      <Score isWebView={isWebView} totalWinAmount={totalWinAmount} />
      <LeaderboardButton isWebView={isWebView} setShowLeaderboard={setShowLeaderboard} />

      <GameMachine></GameMachine>

      <Modal />
      {isShowLanding && <Landing />}

      {isShowLeaderboard && (
        <Leaderboard
          isWebView={isWebView}
          onClose={() => {
            setShowLeaderboard(false);
          }}
        />
      )}

      {isShowHowToPlay && (
        <HowToPlay
          isWebView={isWebView}
          onClose={() => {
            setShowHowToPlay(false);
          }}
        />
      )}

      {!isCookiesAccepted && isShowTermsAndPrivacy && (
        <>
          {appMode === AppModes.TIGER ? (
            <CookiesAcceptTiger
              onClose={() => {
                window.localStorage.setItem('isCookiesAccepted', 'true');
                setCookiesAccepted(true);
              }}
            ></CookiesAcceptTiger>
          ) : (
            <CookiesAccept
              onClose={() => {
                window.localStorage.setItem('isCookiesAccepted', 'true');
                setCookiesAccepted(true);
              }}
            ></CookiesAccept>
          )}
        </>
      )}

      {appMode !== AppModes.TIGER && (
        <S.VideoWrapper $showVideo={showVideo}>
          <video autoPlay ref={videoRef} muted playsInline preload="auto">
            {window.innerWidth > 450 ? (
              <>
                <source
                  src={`${window.location.origin}/video_mp4/web_masked.mp4`}
                  type='video/mp4; codecs="hvc1"'
                ></source>
                <source src={`${window.location.origin}/video_webm/web_masked.webm`} type="video/webm"></source>
              </>
            ) : (
              <>
                <source
                  src={`${window.location.origin}/video_mp4/mobile_masked.mp4`}
                  type='video/mp4; codecs="hvc1"'
                ></source>
                <source src={`${window.location.origin}/video_webm/mobile_masked.webm`} type="video/webm"></source>
              </>
            )}
          </video>
        </S.VideoWrapper>
      )}

      {roundWinAmount && (
        <S.WinAnimation $isTigerUi={appMode === AppModes.TIGER} $showWinAnimation={showWinAnimation}>
          <div>
            <p>{config.winAnimationTitle}</p>
            <p>{numberWithSpaces(roundWinAmount)}</p>
          </div>
        </S.WinAnimation>
      )}
    </S.AppWrapper>
  );
}

export default App;
