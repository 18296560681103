import * as S from './LeaderboardButton.styles';
import { useGameState } from '../../../../providers/GameProvider.hooks';
import { AppModes } from '../../../../providers/GameProvider.types';
import TigerLeaderboardButtonSvg from '../../../../assets/buttons/tiger_leaderboard_button copy.svg';

export function LeaderboardButton({
  isWebView,
  setShowLeaderboard,
}: {
  isWebView: boolean;
  setShowLeaderboard: (state: boolean) => void;
}) {
  const { appMode } = useGameState();

  const renderView = () => {
    switch (appMode) {
      case AppModes.TIGER: {
        return (
          <S.TigerLeaderboardButton
          onClick={() => {
            setShowLeaderboard(true);
          }}
        ><img src={TigerLeaderboardButtonSvg} alt='leaderboard'></img></S.TigerLeaderboardButton>
        );
      }

      default: {
        return (
          <S.LeaderboardButton
            $isWebView={isWebView}
            onClick={() => {
              setShowLeaderboard(true);
            }}
          />
        );
      }
    }
  };
  return renderView();
}
