import styled from 'styled-components';
import CupButtonSvg from '../../../../assets/buttons/cup.webp';


export const LeaderboardButton = styled.div<{ $isWebView: boolean }>`
  position: fixed;
  top: 60px;
  right: 300px;
  height: 72px;
  width: 72px;
  background-image: url(${CupButtonSvg});
  background-size: cover;
  user-select: none;
  -webkit-user-select: none;
  -webkit-tap-highlight-color: transparent;

  cursor: pointer;
  z-index: 10;

  &:hover {
    &:after {
      background: radial-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0));
      position: absolute;
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      top: 0;
    }
  }

  @media (max-width: 1536px) {
    top: inherit;
    bottom: 70px;
    right: 70px;
  }

  @media (max-width: 800px) {
    height: 57px;
    width: 57px;
    top: 25px;
    right: 25px;
    ${props => props.$isWebView && `
      top: 60px;
    `}
  }
`;


export const TigerLeaderboardButton = styled.div`
  position: fixed;
  bottom: calc(50% - 470px);
  right: calc(50% - 210px);
  cursor: pointer;
  z-index: 10;
  @media (max-height: 1100px) or (max-width: 800px) {
    right: calc(50% - 160px);
    bottom: 90px;
  }
  @media (max-height: 1100px) {
    bottom: 120px;
  }
  @media (max-width: 800px) {
    bottom: 90px;
  }

  @media (max-height: 650px) and (max-width: 800px) {
    right: calc(50% - 140px);
    transform: scale(0.7)
  }
`;
