
export type GameContextType = {
  totalWinAmount: number;
  onStart: () => void;
  onFinish: (matches: number[]) => void;
  config: ConfigType;
  roundNumber: number;
  isShowModal: boolean;
  isShowLanding: boolean;
  isRolling: boolean;
  setShowModal: (state: boolean) => void;
  roundWinAmount: number | null;
  isWinningRound: boolean;
  appMode: AppModes;
};

export type ConfigSet = Record<string, ConfigType>

export enum AppModes {
  DEFAULT = 'default',
  TIGER = 'tiger',
}

export type ConfigType = {
  startAmount: number;
  firstShow: number;
  showAfter: number;
  spinButtonText: string;
  closeButtonHidden: boolean;
  titleText: string;
  subTitleText: string;
  leaderboardTitle: string;
  leaderboardSubTitle: string;
  leaderboardUserName: string;
  leaderboard: Array<{ name: string; score: number }>;
  prizes: Record<number, number>;
  claimButtonText: string;
  buttonText: string;
  link: string;
  slotConfig: number[][];
  howToPlayTitle: string;
  howToPlayText: string[];
  winAnimationTitle: string;
  useLanding?: string;
};


export const DEFAULT_CONFIG: ConfigType = {
  startAmount: 1,
  firstShow: 10,
  showAfter: 3,
  spinButtonText: 'Spin',
  closeButtonHidden: false,
  leaderboardTitle: 'Leaderboard',
  leaderboardSubTitle: 'Keep winning to move up the leaderboard',
  leaderboardUserName: 'YOU',
  leaderboard: [
    { name: 'April', score: 1100000 },
    { name: 'Templeton', score: 920000 },
    { name: 'Murdock ', score: 891000 },
    { name: 'Willie', score: 722000 },
    { name: 'Mike', score: 698000 },
    { name: 'Sledg', score: 634000 },
    { name: 'Lynn', score: 599000 },
    { name: 'Angela', score: 434000 },
    { name: 'Hannibal', score: 422000 },
    { name: 'Michael', score: 280000 },
    { name: 'Angus', score: 234000 },
  ],
  prizes: {
    0: -3000,
    1: 22000,
    2: 140000,
  },
  titleText: 'Congrats!',
  subTitleText: 'You’ve got a bonus. Join now to claim it in real cash!',
  claimButtonText: 'Get your prize!',
  buttonText: 'You’ve got a bonus. Join now to claim it in real cash!',
  link: 'https://www.google.com/',
  slotConfig: [
    [8, 7, 2],
    [1, 4, 4],
    [1, 1, 1],
    [2, 6, 6],
    [5, 1, 8],
    [7, 7, 7],
    [2, 1, 3],
    [4, 4, 9],
  ],
  howToPlayTitle: 'Look of Ray: Slots of the Pharaohs',
  howToPlayText: ['Delve into ancient Egypt with Look of Ray: Slots of the Pharaohs—a risk-free mobile slot adventure. Start with a generous 500,000 points and match hieroglyphs to climb the Pyramid Leaderboard.',
    '🎰 Secure 2 matches to gain 20,000 points; 3 matches award you 100,000!',
    '🥇 Daily bonuses keep your journey thrilling.',
    '💬 Connect with fellow explorers and share in the fun.',
    'Note: This game is purely for entertainment. No real money is involved, wagered, or won—pure, safe fun in the world of Pharaohs.',
    '🌞 Ready for a risk-free Egyptian escapade? Download now and spin into history! 🐫'],
  winAnimationTitle: "WIN"
};
