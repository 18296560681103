import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  transition: all 0.3s ease-out;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 101;
  width: 100%;
  height: 100%;
  background-color: rgba(3, 5, 7, 0.85);
  justify-content: center;
  align-items: center;

  @media (max-width: 800px) {
    align-items: end;
  }
`;

export const Modal = styled.div`
  background-color: #5F0706;
  max-width: 500px;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 20px;

  @media (max-width: 800px) {
    position: fixed;
    bottom: 0;
    left: 0;
    max-width: 100%;
    margin: 20px;
    width: calc(100% - 40px);
  }
`;



export const Title = styled.span`
  color:  white;
  font-family: 'Noticia Text';
  font-size: 32px;
  font-weight: 700;
  line-height: 38px;
  user-select: none;
  -webkit-user-select: none;
  margin-top: 24px;
  @media (max-width: 800px) {
    font-size: 24px;
    line-height: 28px;
  }
  cursor: default;

`;

export const SubTitle = styled.span`
  color:  #CC9190;
  cursor: default;
  font-family: 'Noticia Text';
  font-size: 18px;
  font-weight: 400;
  line-height: 19px;
  margin: 10px 24px 24px;
  user-select: none;
  -webkit-user-select: none;
  @media (max-width: 800px) {
    font-size: 16px;
    line-height: 19px;
  }
`;

export const AcceptButton = styled.div`
  width: 90%;
  height: 56px;
  background: linear-gradient(0deg, #FF820B 0%, #FFB300 38%, #FFE05C 100%);
  border-radius: 40px;
  border: 0;
  margin-bottom: 20px;
  z-index: 99;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  :hover {
    opacity: 0.8
  }
`;


export const AcceptButtonInner = styled.div`
  width: calc(100% - 8px);
  height: 48px;
  background: linear-gradient(180deg, #FFEF59 0%, #FFCF18 50.86%, #FF910F 100%);
  border-radius: 40px;
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ButtonText = styled.span`
  color: #350303;
  font-family: 'Noticia Text';
  font-size: 24px;
  font-weight: 700;
  line-height: 38px;
  user-select: none;
  -webkit-user-select: none;
  @media (max-width: 800px) or (max-height: 864px) {
    font-size: 20px;
    line-height: 28px;
  }
`;

