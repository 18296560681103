import * as S from './HelpButton.styles';
import { useGameState } from '../../../../providers/GameProvider.hooks';
import { AppModes } from '../../../../providers/GameProvider.types';
import TigerHelpButtonSvg from '../../../../assets/buttons/tiger_help_button.svg';

export function HelpButton({
  isWebView,
  setShowHowToPlay,
}: {
  isWebView: boolean;
  setShowHowToPlay: (state: boolean) => void;
}) {
  const { appMode } = useGameState();

  const renderView = () => {
    switch (appMode) {
      case AppModes.TIGER: {
        return (
          <S.TigerHelpButton
            onClick={() => {
              setShowHowToPlay(true);
            }}
          ><img src={TigerHelpButtonSvg} alt='information'></img></S.TigerHelpButton>
        );
      }

      default: {
        return (
          <S.HelpButton
            $isWebView={isWebView}
            onClick={() => {
              setShowHowToPlay(true);
            }}
          >
            <S.HelpButtonText>?</S.HelpButtonText>
          </S.HelpButton>
        );
      }
    }
  };
  return renderView();
}
