import * as S from './Score.styles';
import { useGameState } from '../../../../providers/GameProvider.hooks';
import { AppModes } from '../../../../providers/GameProvider.types';
import { numberWithComma, numberWithSpaces } from '../../../../utils/number';

export function Score({ isWebView, totalWinAmount }: { isWebView: boolean; totalWinAmount: number }) {
  const { appMode } = useGameState();

  const renderView = () => {
    switch (appMode) {
      case AppModes.TIGER: {
        return (
          <S.TigerScore $isWebView={isWebView}>
            <S.TigerScoreText>{numberWithComma(totalWinAmount)}</S.TigerScoreText>
          </S.TigerScore>
        );
      }

      default: {
        return (
          <S.Score $isWebView={isWebView}>
            <S.ScoreText>{numberWithSpaces(totalWinAmount)}</S.ScoreText>
          </S.Score>
        );
      }
    }
  };
  return renderView();
}
