import * as S from './HowToPlay.styles';
import { useGameState } from '../../../../providers/GameProvider.hooks';
import { AppModes } from '../../../../providers/GameProvider.types';

export function HowToPlay({ onClose, isWebView }: { onClose: () => void; isWebView: boolean }) {
  const { config, appMode } = useGameState();
  const renderUi = () => {
    switch (appMode) {
      case AppModes.TIGER: {
        return (
          <S.Wrapper>
            <S.TigerModal>
              <S.CloseButton $isWebView={isWebView} onClick={onClose}></S.CloseButton>
              <S.TigerTitle $isWebView={isWebView}>{config.howToPlayTitle}</S.TigerTitle>
              <S.ContentWrapper>
                {config.howToPlayText.map((el, index) => (
                  <S.TigerSubTitle key={index}>{el}</S.TigerSubTitle>
                ))}
              </S.ContentWrapper>
            </S.TigerModal>
          </S.Wrapper>
        );
      }
      default: {
        return (
          <S.Wrapper>
            <S.Modal>
              <S.CloseButton $isWebView={isWebView} onClick={onClose}></S.CloseButton>
              <S.Title $isWebView={isWebView}>{config.howToPlayTitle}</S.Title>
              <S.ContentWrapper>
                {config.howToPlayText.map((el, index) => (
                  <S.SubTitle key={index}>{el}</S.SubTitle>
                ))}
              </S.ContentWrapper>
            </S.Modal>
          </S.Wrapper>
        );
      }
    }
  };
  return renderUi();
}
