import styled from 'styled-components';
import HelpButtonWebp from '../../../../assets/buttons/help_button.webp';


export const HelpButtonText = styled.span`
color: rgba(255, 225, 168, 1);
  font-family: 'Inknut Antiqua', serif;
  font-size: 32px;
  font-weight: 700;
  line-height: 38px;
  user-select: none;
  -webkit-user-select: none;
  @media (max-width: 800px) {
    font-size: 22px;
  }
`;



export const HelpButton = styled.div<{ $isWebView: boolean }>`
  position: fixed;
  top: 60px;
  left: 60px;
  height: 72px;
  width: 72px;
  background-image: url(${HelpButtonWebp});
  background-size: cover;
  cursor: pointer;
  z-index: 10;
  user-select: none;
  -webkit-user-select: none;
  -webkit-tap-highlight-color: transparent;

  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    &:after {
      background: radial-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0));
      position: absolute;
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      top: 0;
    }
  }

  @media (max-width: 800px) {
    height: 40px;
    width: 40px;
    top: 25px;
    left: 25px;
    ${props => props.$isWebView && `
      top: 60px;
    `}
  }
`;


export const TigerHelpButton = styled.div`
  position: fixed;
  bottom: calc(50% - 470px);
  left: calc(50% - 210px);
  cursor: pointer;
  z-index: 10;
  @media (max-height: 1100px) or (max-width: 800px) {
    left: calc(50% - 160px);
    bottom: 90px;
  }
  @media (max-height: 1100px) {
    bottom: 120px;
  }
  @media (max-width: 800px) {
    bottom: 90px;
  }

  @media (max-height: 650px) and (max-width: 800px) {
    left: calc(50% - 140px);
    transform: scale(0.7)
  }
`;
