import * as S from './GameMachine.styles';
import { Spinner } from './Spinner';
import { useGameState } from '../../../../providers/GameProvider.hooks';
import { useMemo, useRef } from 'react';
import { getRandomNumber, numberWithComma, numberWithSpaces } from '../../../../utils/number';
import { isShowTermsAndPrivacy } from '../../../../utils/routes';
import { AppModes } from '../../../../providers/GameProvider.types';

export function GameMachine() {
  const { onFinish, config, roundNumber, roundWinAmount, onStart, isRolling, isWinningRound, appMode } = useGameState();
  let matches: number[] = [];
  let _spinner1 = useRef<Spinner | null>(null);
  let _spinner2 = useRef<Spinner | null>(null);
  let _spinner3 = useRef<Spinner | null>(null);

  const finishHandler = (value: number) => {
    matches.push(value);
    if (matches.length === 3) {
      onFinish(matches);
    }
  };

  const handleClick = () => {
    onStart();
    matches = [];
    if (_spinner1 && _spinner2 && _spinner3) {
      _spinner1.current?.spin();
      _spinner2.current?.spin();
      _spinner3.current?.spin();
    }

    // facebook and google Contact event
    // @ts-ignore
    if (window.fbq) {
      // @ts-ignore
      fbq('track', 'Contact');
    }
    //@ts-ignore
    if (window.gtag) {
      //@ts-ignore
      gtag('event', 'Contact');
    }
  };

  const SPINNER_1 = useMemo(() => {
    const finishNumber = config.slotConfig[roundNumber - 1] ? config.slotConfig[roundNumber - 1][0] : getRandomNumber();

    return (
      <Spinner
        onFinish={finishHandler}
        ref={_spinner1}
        finishNumber={finishNumber}
        timer={1000}
        isWinningRound={isWinningRound}
        isTigerUi={appMode === AppModes.TIGER}
      />
    );
  }, [roundNumber, config, isWinningRound]);

  const SPINNER_2 = useMemo(() => {
    const finishNumber = config.slotConfig[roundNumber - 1] ? config.slotConfig[roundNumber - 1][1] : getRandomNumber();

    return (
      <Spinner
        onFinish={finishHandler}
        ref={_spinner2}
        finishNumber={finishNumber}
        timer={1400}
        isWinningRound={isWinningRound}
        isTigerUi={appMode === AppModes.TIGER}
      />
    );
  }, [roundNumber, config, isWinningRound]);

  const SPINNER_3 = useMemo(() => {
    const finishNumber = config.slotConfig[roundNumber - 1] ? config.slotConfig[roundNumber - 1][2] : getRandomNumber();

    return (
      <Spinner
        onFinish={finishHandler}
        ref={_spinner3}
        finishNumber={finishNumber}
        timer={2200}
        isWinningRound={isWinningRound}
        isTigerUi={appMode === AppModes.TIGER}
      />
    );
  }, [roundNumber, config, isWinningRound]);

  return (
    <S.GameMachineWrapper>
      <div>
        {appMode === AppModes.TIGER ? (
          <>
            <S.TigerMachineWrapper>
              <S.TigerGameMachine />
            </S.TigerMachineWrapper>
            <S.TigerSpinnerWrapper $isWithShadow={isWinningRound && !isRolling}>
              <S.TigerSpinnerContainer>
                {SPINNER_1}
                {SPINNER_2}
                {SPINNER_3}
              </S.TigerSpinnerContainer>
            </S.TigerSpinnerWrapper>
            <S.TigerSpinButton disabled={isRolling} onClick={isRolling ? undefined : handleClick}></S.TigerSpinButton>
            <S.TigerWinAmountWrapper $isWinningRound={isWinningRound}>
              {roundWinAmount && `Win ${numberWithComma(roundWinAmount)}`}
            </S.TigerWinAmountWrapper>

            <S.TigerTermsWrapper>
              <S.TigerTermsText>
                This website is designed exclusively for entertainment purposes. The games available on this site do not
                involve real money, bets, or winnings. All activities on this website are purely for fun and amusement.
                It's important to note that this website is intended for adult users (18+) and does not provide
                opportunities for "real money gambling" or the chance to win actual money or prizes. If you require
                assistance in managing your gambling activity responsibly, consider seeking support from gambling
                therapy services.
              </S.TigerTermsText>
              <S.TigerLinksWrapper>
                <S.TigerLink
                  onClick={() => {
                    window.open(`${window.location.origin}/fortunetiger/privacy.html`, '_blank');
                  }}
                >
                  PRIVACY POLICY
                </S.TigerLink>
                <S.TigerLink
                  onClick={() => {
                    window.open(`${window.location.origin}/fortunetiger/terms.html`, '_blank');
                  }}
                >
                  TERMS OF USE
                </S.TigerLink>
              </S.TigerLinksWrapper>
            </S.TigerTermsWrapper>
          </>
        ) : (
          <>
            <S.GameMachine />
            <S.SpinnerWrapper $isWithShadow={isWinningRound && !isRolling}>
              <S.SpinnerContainer>
                {SPINNER_1}
                {SPINNER_2}
                {SPINNER_3}
              </S.SpinnerContainer>
            </S.SpinnerWrapper>
            <S.SpinButton disabled={isRolling} onClick={isRolling ? undefined : handleClick}>
              <S.ButtonText>{config.spinButtonText}</S.ButtonText>
            </S.SpinButton>
            {isShowTermsAndPrivacy && (
              <S.TermsWrapper>
                <S.TermsText>
                  This website is designed exclusively for entertainment purposes. The games available on this site do
                  not involve real money, bets, or winnings. All activities on this website are purely for fun and
                  amusement. It's important to note that this website is intended for adult users (18+) and does not
                  provide opportunities for "real money gambling" or the chance to win actual money or prizes. If you
                  require assistance in managing your gambling activity responsibly, consider seeking support from{' '}
                  <a target="_blank" href="https://www.kethea.gr/en/ ">
                    gambling therapy services
                  </a>
                  . Look of Ray maintains no responsibility for any losses, damages, or negative consequences that may
                  arise from the use or misuse of the information or simulator provided on our website. Users are
                  advised to engage responsibly, acknowledging the potential risks involved, including the development
                  of addictive behavior. It is essential to approach the simulator with caution and awareness of its
                  simulated nature, ensuring a mindful and enjoyable experience.
                </S.TermsText>
                <S.LinksWrapper>
                  <S.Link
                    onClick={() => {
                      window.open('https://lookofray.com/lookofray/privacy.html', '_blank');
                    }}
                  >
                    PRIVACY POLICY
                  </S.Link>

                  <S.Link
                    onClick={() => {
                      window.open('https://lookofray.com/lookofray/terms.html', '_blank');
                    }}
                  >
                    TERMS OF USE
                  </S.Link>
                </S.LinksWrapper>
              </S.TermsWrapper>
            )}
            <S.WinAmountWrapper $isWinningRound={isWinningRound}>
              <S.WinAmount>{roundWinAmount && numberWithSpaces(roundWinAmount)}</S.WinAmount>
            </S.WinAmountWrapper>
          </>
        )}
      </div>
    </S.GameMachineWrapper>
  );
}
